<template>
<b-card class="text-center">
    <p style="margin-bottom: 0.7em !important; font-size: 1em;" class="font-weight-bolder">{{$t('Fuel')}}</p>
    <vue-apex-charts  type="radialBar" height="110" :options="goalOverviewRadialBar.chartOptions" :series="goalOverviewRadialBar.series" />
    
</b-card>
</template>

<script>
import {
    BCard,
    BAvatar
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {
    $themeColors
} from '@themeConfig'

export default {
    components: {
        BCard,
        VueApexCharts,
        BAvatar,

    },
    props: {
        fuel: {
            type: Number,
            required: true,
        },

    },
    data() {
        return {
            goal_overview: {},
            goalOverviewRadialBar: {
                series: [this.fuel],
                chartOptions: {
                    chart: {
                        sparkline: {
                            enabled: true,
                        },
                        dropShadow: {
                            enabled: true,
                            blur: 3,
                            left: 1,
                            top: 1,
                            opacity: 0.1,
                        },
                    },
                    colors: ['#51e5a8'],
                    plotOptions: {
                        radialBar: {
                            offsetY: -10,
                            startAngle: -150,
                            endAngle: 150,
                            hollow: {
                                size: '65%',
                            },
                            track: {
                                background: '#ebe9f1',
                                strokeWidth: '50%',
                            },
                            dataLabels: {
                                name: {
                                    show: false,
                                },
                                value: {
                                    color: '#5e5873',
                                    fontSize: '2rem',
                                    fontWeight: '600',
                                },
                            },
                        },
                    },
                    fill: {
                        type: 'gradient',
                        gradient: {
                            shade: 'dark',
                            type: 'horizontal',
                            shadeIntensity: 0.5,
                            gradientToColors: [$themeColors.info],
                            inverseColors: true,
                            opacityFrom: 1,
                            opacityTo: 1,
                            stops: [0, 100],
                        },
                    },
                    stroke: {
                        lineCap: 'round',
                    },
                    grid: {

                    },
                },
            },
        }
    },
    created() {
        //this.$http.get('/card/card-analytics/goal-overview').then(res => { this.goal_overview = res.data })
    },
}
</script>
