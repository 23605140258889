<template>
<b-card>

    <div class="d-flex align-items-center justify-content-between mb-1">
        <h4 class="d-inline-block mr-1" >{{$t('Status History')}}</h4> 
        <b-button  :to="{ name: 'queries-weekly-work-status', params: { id: assetId } }" class="float-right mb-0"  variant="primary" size="sm">{{$t('Weekly')}}</b-button>
    </div>
  <b-row>
    <b-col md="12">
      <div class="container mb-1">
        <div><p class="font-weight-bolder">Legenda</p></div>
        <div class="row">
          <div class="col-md-2 col-sm-4 status">
            <span class="dot dot-off"></span>
            <span class="font-weight-bolder font-small-2">Desligado</span>
          </div>
          <div class="col-md-2 col-sm-4 status">
            <span class="dot dot-on"></span>
            <span class="font-weight-bolder font-small-2">Ligado</span>
          </div>
          <div class="col-md-2 col-sm-4 status">
            <span class="dot dot-no-connection"></span>
            <span class="font-weight-bolder font-small-2">Sem Conexão</span>
          </div>
          <div class="col-md-2 col-sm-4 status">
            <span class="dot dot-idle"></span>
            <span class="font-weight-bolder font-small-2">Uso Ocioso</span>
          </div>
          <div class="col-md-2 col-sm-4 status">
            <span class="dot dot-severe-use"></span>
            <span class="font-weight-bolder font-small-2">Uso Severo</span>
          </div>
          <div class="col-md-2 col-sm-4 status">
            <span class="dot dot-failure"></span>
            <span class="font-weight-bolder font-small-2">Falha</span>
          </div>
        </div>
      </div>
    </b-col>
  </b-row>

    
    <div id="chart">
        <vue-apex-charts type="rangeBar" height="135" :options="chartOptions" :series="series"></vue-apex-charts>
    </div>
</b-card>
</template>

<script>
import {
  BCard,
  BAvatar,
  BButton, BRow, BCol
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'
import {
    $themeColors
} from '@themeConfig'

export default {
    components: {
      BCol, BRow,
        BCard,
        BAvatar,
        VueApexCharts,
        BButton
    },
    data() {
        return {
            series: this.dataWorkStatus,
            /*
            series: [
                // on
                {
                    name: 'on',
                    data: [{
                        x: 'Status',
                        y: [
                            utcToLocalDate(new Date(2022, 7, 28, 1, 50)),
                            utcToLocalDate(new Date(2022, 7, 28, 8))
                        ],
                        fillColor: '#FEB019'
                    }, ]
                },
                // Off
                {
                    name: 'off',
                    data: [{
                            x: 'Status',
                            y: [
                                utcToLocalDate(new Date(2022, 7, 28, 8)),
                                utcToLocalDate(new Date(2022, 7, 28, 9, 30))
                            ],
                            fillColor: '#008FFB'
                        },

                    ]
                },
                // no-communication
                {
                    name: 'no-communication',
                    data: [{
                            x: 'Status',
                            y: [
                                utcToLocalDate(new Date(2022, 7, 28, 9, 30)),
                                utcToLocalDate(new Date(2022, 7, 28, 11))
                            ],
                            fillColor: '#99855A'
                        },

                    ]
                },
                // servere-use
                {
                    name: 'servere-use',
                    data: [{
                            x: 'Status',
                            y: [
                                utcToLocalDate(new Date(2022, 7, 28, 11)),
                                utcToLocalDate(new Date(2022, 7, 28, 15, 35))
                            ],
                            fillColor: '#FF4560'

                        },

                    ]
                },
            ],*/

            chartOptions: {
                chart: {
                    height: 135,
                    type: 'rangeBar',
                    toolbar: {
                        show: true,
                        tools: {
                            download: false,
                            pan: false,
                            zoom: true,
                            reset: true,
                        },
                    },
                    
                },
                
                
                plotOptions: {
                    bar: {
                        horizontal: true,
                        barHeight: '50%',
                        rangeBarGroupRows: true
                    },
                    dataLabels: {
                        enabled: false,

                    },
                },
                //colors: [$themeColors.success, $themeColors.secondary, $themeColors.primary, $themeColors.warning],

                fill: {
                    type: 'solid'
                },
                xaxis: {
                    type: 'datetime',
                    min: resolveMin(),
                    max: resolveMax()
                },
                yaxis: {
                    labels: false,
                },
                legend: {
                    show: false,
                    //position: 'bottom'
                },
                tooltip: {
                    custom(data) {
                        const hourStart = new Date(data.y1).getUTCHours();
                        const minuteStart = new Date(data.y1).getUTCMinutes();
                        const hourEnd = new Date(data.y2).getUTCHours();
                        const minuteEnd = new Date(data.y2).getUTCMinutes();
                        const values = data.ctx.rangeBar.getTooltipValues(data);
                        return `${'<div class="px-1 py-50"><span>'}${
                          values.seriesName + hourStart + ":" + minuteStart + " to " +  hourEnd + ":" + minuteEnd
                        }</span></div>`
                    },
                },

            },

        }
    },
    mounted() {
        console.log(` ARR status card `, this.dataWorkStatus)
    },
    watch: {
        dataWorkStatus: function (newVal, oldVal) { // watch it
            console.log('Prop changed: ', newVal, ' | was: ', oldVal)
        }
    },
    props: {
        dataWorkStatus: {
            type: Array,
            required: true,
            default: []
        },
        assetId:{
            type: String,
            required: true
        }

    },
    methods: {
        /*
        utcToLocalDate(date) {
            var myDate = date;
            var offset = myDate.getTimezoneOffset() * 60 * 1000;

            var withOffset = myDate.getTime();
            var withoutOffset = withOffset - offset;

            return withoutOffset;
        },

        resolveMin(){
            const dt = new Date();
            dt.setHours(4);
            dt.setMinutes(0);

            return this.utcToLocalDate(dt);
        },

        resolveMax(){
            const dt = new Date();
            dt.setHours(20);
            dt.setMinutes(0);

            return this.utcToLocalDate(dt);
        }*/

    },
    
}

function utcToLocalDate(date) {
            var myDate = date;
            var offset = myDate.getTimezoneOffset() * 60 * 1000;

            var withOffset = myDate.getTime();
            var withoutOffset = withOffset - offset;

            return withoutOffset;
        }

        function    resolveMin(){
            const dt = new Date();
            dt.setHours(4);
            dt.setMinutes(0);

            return utcToLocalDate(dt);
        }

        function    resolveMax(){
            const dt = new Date();
            dt.setHours(20);
            dt.setMinutes(0);

            return utcToLocalDate(dt);
        }
</script>

<style scoped>
.status {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px 0;
}

.dot {
  height: 15px;
  width: 15px;
  border-radius: 50%;
  display: inline-block;
  margin-bottom: 5px;
}

.dot-off {
  background-color: #747679;
}

.dot-on {
  background-color: #47cd83;
}

.dot-no-connection {
  background-color: rgba(198, 217, 245, 0.85);
}

.dot-idle {
  background-color: #25d4ea;
}

.dot-severe-use {
  background-color: #feab5e;
}

.dot-failure {
  background-color: #ea5455;
}
</style>
