<template>
    <div>
        <b-card>
            <h5 class="text-center">{{ model }} - {{ identifier }} </h5>
            <b-row class="mb-1">
                <b-col md="6" sm="12" v-show="sse">
                    <b-badge variant="danger" class="d-block badge-glow">
                        <feather-icon icon="BatteryChargingIcon" size="24" />
                        <span class="ml-1">{{ $t('Power disconnected') }}</span>
                    </b-badge>
                </b-col>
                <b-col md="6" sm="12" v-show="ssd">
                    <b-badge variant="warning" class="d-block badge-glow">

                        <feather-icon icon="PackageIcon" size="24" />
                        <span class="ml-1">{{ $t('No access to data') }}</span>
                    </b-badge>
                </b-col>
            </b-row>
            <h6>Entradas</h6>
            <b-row>
                <b-col md="6" sm="12">
                    <div class="form-check form-check-inline  mt-1">
                        <label class="form-check-label " for="checkbox1">01 - Corte de Cabo: </label>
                        <b-badge style="margin-left: 0.3rem;" pill :variant="resolveVariant(input01)">
                            <feather-icon :icon="resolveIcon(input01)" size="16" :class="resolveClass(input01)" />
                            <span v-show="input01" class="ml-1">{{ $t('Yes') }}</span>
                            <span v-show="!input01" class="ml-1">{{ $t('No') }}</span>
                        </b-badge>
                    </div>
                </b-col>
                <b-col md="6" sm="12">
                    <div class="form-check form-check-inline  mt-1">
                        <label class="form-check-label" for="checkbox1">02 - Porta Aberta: </label>
                        <b-badge style="margin-left: 0.3rem;" pill :variant="resolveVariant(input02)">
                            <feather-icon :icon="resolveIcon(input02)" size="16" :class="resolveClass(input02)" />
                            <span v-show="input02" class="ml-1">{{ $t('Yes') }}</span>
                            <span v-show="!input02" class="ml-1">{{ $t('No') }}</span>
                        </b-badge>
                    </div>
                </b-col>
            </b-row>
            <b-row>
                <b-col md="6" sm="12">
                    <div class="form-check form-check-inline  mt-1">
                        <label class="form-check-label" for="checkbox1">03 - Alarme Desarmado:</label>

                        <b-badge style="margin-left: 0.3rem;" pill :variant="resolveVariant(input03)">
                            <feather-icon :icon="resolveIcon(input03)" size="16" :class="resolveClass(input03)" />
                            <span v-show="input03" class="ml-1">{{ $t('Yes') }}</span>
                            <span v-show="!input03" class="ml-1">{{ $t('No') }}</span>
                        </b-badge>
                    </div>
                </b-col>
                <b-col md="6" sm="12">
                    <div class="form-check form-check-inline mt-1">
                        <label class="form-check-label " for="checkbox1">04 - Pivô Parado:</label>

                        <b-badge style="margin-left: 0.1rem;" pill :variant="resolveVariant(input04)">
                            <feather-icon :icon="resolveIcon(input04)" size="16" :class="resolveClass(input04)" />
                            <span v-show="input04" class="ml-1">{{ $t('Yes') }}</span>
                            <span v-show="!input04" class="ml-1">{{ $t('No') }}</span>
                        </b-badge>
                    </div>
                </b-col>
            </b-row>

            <hr class="border-bottom">
            <h6>Saídas</h6>
            <b-row>
                <b-col md="6" sm="12">
                    <div class="form-check mt-1">
                        <label class="d-block" for="checkbox1">01 - Ligado/Desligado</label>
                        <!-- <b-form-checkbox v-show="!isLoadingOut1" v-model="output01" @change="emitInput01Clicked" class="float-left text-dark" switch -->
                        <b-form-checkbox v-show="!isLoadingGlobal" v-model="output01" class="float-left text-dark" switch
                            inline>{{ output01 ? 'Ligado' : 'Desligado'
                            }}</b-form-checkbox>
                        <!-- <b-spinner small v-show="isLoadingOut1" class="mr-1" variant="primary"/> -->
                        <b-spinner small v-show="isLoadingGlobal" class="mr-1" variant="primary" />
                        <!-- <label v-show="isLoadingOut1">Aguardando resposta.</label> -->
                        <label v-show="isLoadingGlobal">Aguardando resposta.</label>
                    </div>
                </b-col>
                <b-col md="6" sm="12">
                    <div class="form-check mt-1">
                        <label class="d-block" for="checkbox1">02 - Avanço/Reverção</label>
                        <!-- <b-form-checkbox v-show="!isLoadingOut2" v-model="output02" @change="emitInput02Clicked" class="" switch -->
                        <b-form-checkbox v-show="!isLoadingGlobal" v-model="output02" class="" switch inline>{{ output02 ?
                            'Avanço' : 'Reverção' }}</b-form-checkbox>
                        <!-- <b-spinner small v-show="isLoadingOut2" class="mr-1" variant="primary"/> -->
                        <b-spinner small v-show="isLoadingGlobal" class="mr-1" variant="primary" />
                        <!-- <label v-show="isLoadingOut2">Aguardando resposta.</label> -->
                        <label v-show="isLoadingGlobal">Aguardando resposta.</label>
                    </div>

                </b-col>
            </b-row>
            <b-row class="mt-md-2">
                <b-col md="6" sm="12" class="mt-1 ">
                    <div class="form-check ">
                        <label class="d-block" for="checkbox1">03 - Com água/Sem água</label>
                        <!-- <b-form-checkbox v-show="!isLoadingOut3" v-model="output03" @change="emitInput03Clicked" class="float-left" switch -->
                        <b-form-checkbox v-show="!isLoadingGlobal" v-model="output03" class="float-left" switch inline>{{
                            output03 ? 'Com água' : 'Sem água' }}</b-form-checkbox>
                        <!-- <b-spinner small v-show="isLoadingOut3" class="mr-1" variant="primary"/> -->
                        <b-spinner small v-show="isLoadingGlobal" class="mr-1" variant="primary" />
                        <!-- <label v-show="isLoadingOut3">Aguardando resposta.</label> -->
                        <label v-show="isLoadingGlobal">Aguardando resposta.</label>
                    </div>
                </b-col>
            </b-row>
            <b-row class="mt-2 ">

                <b-col md="6" sm="12">
                    <label for="rangeInput">Percentímetro %</label>
                    <div v-show="!isLoadingGlobal" class="input-group mb-3">

                        <b-form-input type="number" min="0" max="100" @input="validateInput" class="form-control"
                            id="rangeInput" v-model="inputRange01" />
                        <div class="input-group-append">
                            <span class="input-group-text" id="basic-addon1">%</span>
                            <!-- <b-button variant="primary" class="btn-sm" @click="emitInputRange01">Enviar</b-button> -->
                        </div>

                    </div>
                    <div v-show="isLoadingGlobal">
                        <b-spinner small v-show="isLoadingGlobal" class="mr-1" variant="primary" />
                        <!-- <label v-show="isLoadingRange01">Aguardando resposta.</label> -->
                        <label v-show="isLoadingGlobal">Aguardando resposta.</label>
                    </div>
                </b-col>

            </b-row>
            <b-row class="d-flex justify-content-center">
                <b-button v-show="!isLoadingGlobal" class="mb-1"
                    v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-primary" @click="emitSendCommand"
                    type="button">
                    {{ $t('Send Command') }}
                </b-button>
            </b-row>
        </b-card>
    </div>
</template>
    
<script>
import {
    BCard,
    BCardText,
    BRow,
    BCol,
    BBadge,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckbox,
    BFormInput,
    BTooltip,
    BSpinner,
    BButton
} from 'bootstrap-vue'
import { integer } from 'vee-validate/dist/rules';
//import SimpleIndicatorCard from './SimpleIndicatorCard.vue'

export default {
    components: {
        BCard,
        BCardText,
        BRow,
        BCol,
        BBadge,
        BFormRadioGroup,
        BFormGroup,
        BFormCheckbox,
        BFormInput,
        BTooltip,
        BSpinner,
        BButton
        //SimpleIndicatorCard
    },
    
    props: {
        model: {
            type: String,
            required: true,
        },
        identifier: {
            type: String,
            required: true,
        },
        input01: {
            type: String,
            required: true,
        },
        input02: {
            type: String,
            required: true,
        },
        input03: {
            type: String,
            required: true,
        },
        input04: {
            type: String,
            required: true,
        },
        output01: {
            type: String,
            required: true,
        },
        output02: {
            type: String,
            required: true,
        },
        output03: {
            type: String,
            required: true,
        },
        output04: {
            type: String,
            required: true,
        },
        inputRange01: {
            type: String,
            required: true
        },

        isLoadingOut1: {
            type: Boolean,
            required: true
        },
        isLoadingOut2: {
            type: Boolean,
            required: true
        },
        isLoadingOut3: {
            type: Boolean,
            required: true
        },
        isLoadingRange01: {
            type: Boolean,
            required: true
        },
        ssd: {
            type: Boolean,
            required: true
        },
        sse: {
            type: Boolean,
            required: true
        },

        isLoadingGlobal: {
            type: Boolean,
            required: true
        },
        // hasChanged: {
        //     type: Boolean,
        //     required: false
        // }

    },
    mounted() {

    },
    watch: {
        
    },
    methods: {
        //emitInput04Clicked
        emitInput01Clicked() {
            this.$emit('input01-clicked', this.output01);
        },
        emitInput02Clicked() {
            this.$emit('input02-clicked', this.output02);
        },
        emitInput03Clicked() {
            this.$emit('input03-clicked', this.output03);
        },
        emitInput04Clicked() {
            this.$emit('input04-clicked', this.output04);
        },

        emitInputRange01() {
            this.$emit('input-range01-clicked', this.inputRange01);
        },

        emitSendCommand() {
            let outputs = {
                ou1: this.output01,
                ou2: this.output02,
                ou3: this.output03,
                ou4: this.inputRange01,
            }
            this.$emit('emit-send-command', outputs);
        },

        resolveVariant(input) {
            return input ? 'light-primary' : 'light-secondary'
        },
        resolveIcon(input) {
            return input ? 'CheckCircleIcon' : 'MinusCircleIcon'
        },
        resolveClass(input) {
            return input ? 'text-primary' : 'text-dark'
        },

        validateInput() {
            if (this.inputValue < 0) {
                this.inputValue = 0;
            } else if (this.inputValue > 100) {
                this.inputValue = 100;
            }
        }
    }
}
</script>


    

    