import axios from '@axios'

export default {
    namespaced: true,
    state: {},
    getters: {},
    mutations: {},
    actions: {
        fetchAssets(ctx, queryParams) {


            var qs = ''
            if (queryParams.customerId === undefined) queryParams.customerId = ''
            if (queryParams.sortBy === undefined) queryParams.sortBy = ''


            if (queryParams.workStatusList && queryParams.workStatusList.length > 0) {
                queryParams.workStatusList.map(status => {
                    qs += `&WorkStatusList=${status.name}`
                })

            }

            if (queryParams.sortDesc !== undefined && queryParams.sortDesc == false) {
                queryParams.sortDesc = 'asc'
            } else queryParams.sortDesc = 'desc'



            return new Promise((resolve, reject) => {

                axios
                    //.get('/apps/asset/assets', { params: queryParams })
                    .get(`/api/v1/Asset/search?Search=${queryParams.q}&OrdinationField=${queryParams.sortBy}&OrdinationType=${queryParams.sortDesc}&CustomerId=${queryParams.customerId}&Page=${queryParams.page}&PerPage=${queryParams.perPage}${qs}`)
                    //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAsset(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/asset/assets/${id}`)
                    .get(`/api/v1/Asset?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAssetSummary(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/asset/assets/${id}`)
                    .get(`/api/v1/Asset/summary?Id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addAsset(ctx, assetData) {
            return new Promise((resolve, reject) => {

                let asset = assetData.assetData
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset', asset)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        updateAsset(ctx, assetData) {
            return new Promise((resolve, reject) => {

                let asset = assetData.assetData
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .put('/api/v1/Asset', asset)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteAsset(ctx, { id }) {
            return new Promise((resolve, reject) => {

                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .delete(`/api/v1/Asset?Id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAssetTelemetryParameters(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/asset/assets/${id}`)
                    .get(`/api/v1/Asset/gettelemetryparameters?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAssetTelemetry(ctx, { id, param, dtS, dtE, page }) {

            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/asset/assets/${id}`)
                    .get(`/api/v1/Telemetry/search?AssetId=${id}&Parameter=${param}&Start=${dtS}&End=${dtE}&Page=${page}&PerPage=100`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAssetWorkstatusHistory(ctx, { id, dt }) {

            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/asset/assets/${id}`)
                    .get(`/api/v1/queries/telemetry/workstatushistory?AssetId=${id}&Day=${dt}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCustomers(ctx, queryParams) {

            return new Promise((resolve, reject) => {

                axios
                    //.get('/apps/asset/assets', { params: queryParams })
                    .get(`/api/v1/Customer/search?Search=${queryParams.q}&Page=1&PerPage=5`)
                    //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchCustomer(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/user/users/${id}`)
                    .get(`/api/v1/Customer?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAssetsManufacture(ctx,) {

            return new Promise((resolve, reject) => {

                axios
                    //.get('/apps/asset/assets', { params: queryParams })
                    .get(`/api/v1/Asset/searchmanufacturer?Page=1&PerPage=100`)
                    ///api/v1/Asset/searchmanufacturer?Page=1&PerPage=10
                    //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchTypesWorkStatus() {
            return new Promise((resolve, reject) => {

                axios
                    .get(`/api/v1/Asset/searchworkstatus`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAssetsModel(ctx, { id, page }) {

            return new Promise((resolve, reject) => {

                axios
                    //.get('/apps/asset/assets', { params: queryParams })
                    .get(`/api/v1/Asset/searchmodel?ManufacturerId=${id}&Page=${page}&PerPage=100`)
                    ///api/v1/Asset/searchmanufacturer?Page=1&PerPage=10
                    //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAssetsType(ctx,) {

            return new Promise((resolve, reject) => {

                axios
                    //.get('/apps/asset/assets', { params: queryParams })
                    .get(`/api/v1/Asset/searchtype`)
                    ///api/v1/Asset/searchmanufacturer?Page=1&PerPage=10
                    //.get(`/api/v1/Asset/search?Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchFences(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    //.get('/apps/user/users', { params: queryParams })
                    .get(`/api/v1/VirtualFence/search?Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchFence(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/user/users/${id}`)
                    .get(`/api/v1/VirtualFence?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteFence(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .delete(`/api/v1/VirtualFence?Id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchPoints(ctx, queryParams) {
            return new Promise((resolve, reject) => {
                axios
                    //.get('/apps/user/users', { params: queryParams })
                    .get(`/api/v1/PointOfInterest/search?Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchPoint(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/user/users/${id}`)
                    .get(`/api/v1/PointOfInterest?id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deletePoint(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .delete(`/api/v1/PointOfInterest?Id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        fetchAlarms(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.get(`/apps/user/users/${id}`)
                    .get(`/api/v1/Asset/eventgenerator/search?AssetId=${id}&Page=1&PerPage=100`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addMoveAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/move', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addAlarmWeekendMove(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/weekendmove', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addIgnitionAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/ignition', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addSevereAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/severeuse', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        addIddleAlarm(ctx, alarm) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .post('/api/v1/Asset/eventgenerator/iddleuse', alarm)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        deleteAlarm(ctx, { id }) {
            return new Promise((resolve, reject) => {
                axios
                    //.post('/apps/customer/customers', { customer: customerData })
                    .delete(`/api/v1/Asset/eventgenerator?Id=${id}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        exportAssetsExcel(ctx) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `api/v1/queries/telemetry/ExportAssetsExcel`,
                    method: 'GET',
                    responseType: 'blob', // important
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        exportAssetsPDF(ctx) {
            return new Promise((resolve, reject) => {
                axios({
                    url: `api/v1/queries/telemetry/ExportAssetsPdf`,
                    method: 'GET',
                    responseType: 'blob', // important
                })
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        fetchDevices(ctx, queryParams) {

            console.log('Parametros fetch device: ', queryParams)
            return new Promise((resolve, reject) => {
                axios
                    //.get('/apps/device/devices', { params: queryParams })
                    .get(`/api/v1/device/search?Search=${queryParams.q}&Page=${queryParams.page}&PerPage=${queryParams.perPage}`)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },

        sendDeviceTelecommand(ctx, command) {
            console.log('Comando enviado: ', command.data)
            return new Promise((resolve, reject) => {
                axios
                    .post('/api/v1/Device/SendDeviceTelecommand', command)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },
        sendBulkDeviceTelecommand(ctx, command) {
            console.log('Comando enviado: ', command)

            return new Promise((resolve, reject) => {
                axios
                    .post('/api/v1/Device/SendBulkDeviceTelecommand', command)
                    .then(response => resolve(response))
                    .catch(error => reject(error))
            })
        },




    },
}