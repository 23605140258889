<template>
<div class="customizer " :class="{'open': isCustomizerOpen}">
    <!-- Toggler -->
    <b-link class="customizer-toggle d-flex align-items-center justify-content-center" @click="isCustomizerOpen = !isCustomizerOpen">
        <feather-icon icon="SlidersIcon" size="25" />
    </b-link>
    <!-- /Toggler -->

    <!-- Header -->
    <div class="customizer-section d-flex justify-content-between align-items-center">
        <div>
            <h4 class="text-uppercase mb-0">
                {{$t('Asset Options')}}
            </h4>
            <span style="display: none;">{{teste.model}}</span>
            <small>{{$t('Get access to the various information and reports of your asset')}}</small>
        </div>
        <feather-icon icon="XIcon" size="18" class="cursor-pointer" @click="isCustomizerOpen = !isCustomizerOpen" />
    </div>
    <!-- Header -->

    <vue-perfect-scrollbar :settings="perfectScrollbarSettings" class="ps-customizer-area scroll-area mb-2">

        <!-- SECTION: Reports -->
        <div class="customizer-section">
            <h4>{{$t('Reports')}}</h4>
            <!-- Button: Daily Journey -->
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" class="mb-75" block :to="{ name: 'queries-telemetry', params: { id: myId}, query: { journey: 1 } }">
                {{$t('Daily Journey')}}
            </b-button>

            <!-- Button: Route -->
            <b-button v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" block :to="{ name: 'queries-telemetry', params: { id: myId}, query: { journey: 0 } }">
                {{$t('Route')}}
            </b-button>

            <!-- Button: Monthly Telemetry -->
            <b-button  v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" block :to="{ name: 'queries-month', params: { id: myId } }">
                {{ $t('Monthly Telemetry')}}
            </b-button>

            <!-- Button: Telemetry History  -->
            <b-button class="mb-2" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" block :to="{ name: 'telemetry-table', params: { id: myId } }">
                {{ $t('Telemetry History')}}
            </b-button> 

            <!-- Button: Alarms  -->
            <b-button class="mb-2" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="outline-primary" block :to="{ name: 'settings-alarms', params: { id: myId } }">
                {{ $t('Alarms')}}
            </b-button> 

        </div>

        <!-- SECTION: utilities  -->
        <div class="customizer-section">
            <h4>{{$t('Utilities')}}</h4>

            <b-button class="mb-1" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-primary" block @click="getCriterion" type="button">
                {{$t('Virtual Fence')}}
            </b-button>

            <b-button class="mb-1" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-primary" block @click="getCriterionPoint" type="button">
                {{$t('Point Of Interest')}}
            </b-button>

            <b-button class="mb-1" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-primary" block :to="{ name: 'maintenance', params: { id: myId } }" type="button">
                {{$t('Maintenance')}}
            </b-button>

            <b-button class="mb-1" v-ripple.400="'rgba(113, 102, 240, 0.15)'" variant="gradient-primary" block :to="{ name: 'geo-data', params: { id: myId } }" type="button">
                {{$t('Geo Data')}}
            </b-button>

        </div>

        

    </vue-perfect-scrollbar>
</div>
</template>

<script>
import {
    BLink,
    BFormRadioGroup,
    BFormGroup,
    BFormCheckbox,
    BListGroup,
    BListGroupItem,
    BBadge,
    BButton,
    VBTooltip
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'
import useAppCustomizer from '@core/layouts/components/app-customizer/useAppCustomizer.js'

import {
    ref,
    onUnmounted
} from '@vue/composition-api'

import store from '@/store'
import assetStoreModule from '@/views/main/asset/assetStoreModule'

export default {
    components: {
        // BSV
        BLink,
        BFormRadioGroup,
        BFormCheckbox,
        BFormGroup,

        // 3rd party
        vSelect,
        VuePerfectScrollbar,
        BListGroup,
        BListGroupItem,
        BBadge,
        BButton,

    },
    directives: {
        'b-tooltip': VBTooltip,
    },
    data() {
        return {
            teste: {
                model: true
            },
            titleTootipMove: this.$t('Turn on to be notified when the vehicle starts moving'),
            titleTootipWeekendMove: this.$t('Activate to be notified when the vehicle starts moving on weekends only'),
            titleTootipIgnition: this.$t('Turn on to be notified when the vehicle starts') ,
            titleTootipSevereUse: this.$t('Turn on to be notified when vehicle is in severe use') ,
            titleTootipIddleUse: this.$t('Enable to be notified when vehicle is in idle use') ,

        };

    },
    props: {
        myId: {
            type: String,
            required: true,
        },

    },
    mounted() {

        this.fetchAlarms()
    },
    methods: {
        getCriterion() {
            this.$emit('event-criterion')
        },
        getCriterionPoint() {
            this.$emit('event-point')
        },
        getMaintenancePlan(){
            this.$emit('event-maintenance-plan')
        },
        msgDialog(msg, icon) {
            this.$swal({
                position: 'top-end',
                icon: icon,
                title: msg,
                showConfirmButton: false,
                timer: 1500 * 3,
                customClass: {
                    confirmButton: 'btn btn-primary',
                },

                buttonsStyling: false,
            })
        },
        toogleAlarm(typeAlarm) {
            console.log('ENTROU NO TOOGLE', typeAlarm)
            switch (typeAlarm) {
                case this.MOVE_ALARM_NAME:
                    //this.alarmMove.model ? this.createAlarmMove() : this.deleteAlarm(this.alarmMove.id, this.MOVE_ALARM_NAME)
                    this.alarmMove.model ? this.createAlarmMove() : this.deleteAlarm(this.alarmMove.id, this.MOVE_ALARM_NAME)
                    break;
                case this.WEEKEND_MOVE_ALARM_NAME:
                    this.alarmWeekendMove.model ? this.createAlarmWeekendMove() : this.deleteAlarm(this.alarmWeekendMove.id, this.WEEKEND_MOVE_ALARM_NAME)
                    break;
                case this.IGNITION_ALARM_NAME:
                    this.alarmIgnition.model ? this.createAlarmIgnition() : this.deleteAlarm(this.alarmIgnition.id, this.IGNITION_ALARM_NAME)
                    break;
                case this.SEVERE_USE_ALARM_NAME:
                    this.alarmSevere.model ? this.createAlarmSevere() : this.deleteAlarm(this.alarmSevere.id, this.SEVERE_USE_ALARM_NAME)
                    break;
                case this.IDDLE_USE_ALARM_NAME:
                    this.alarmIddle.model ? this.createAlarmIddle() : this.deleteAlarm(this.alarmIddle.id, this.IDDLE_USE_ALARM_NAME)
                    break;
                default:
                    console.log('teste')
            }
        },
        fetchAlarms() {

            store.dispatch('app-asset/fetchAlarms', {
                    //id: router.currentRoute.params.id
                    id: this.myId
                })
                .then(res => {

                    if (res.data.data.length > 0) {
                        let alarms = res.data.data
                        alarms.map(alarm => {
                            if (alarm.name == this.MOVE_ALARM_NAME) {
                                this.alarmMove.model = true;
                                this.alarmMove.id = alarm.id
                            } else if (alarm.name == this.WEEKEND_MOVE_ALARM_NAME) {
                                this.alarmWeekendMove.model = true;
                                this.alarmWeekendMove.id = alarm.id
                            } else if (alarm.name == this.IGNITION_ALARM_NAME) {
                                this.alarmIgnition.model = true;
                                this.alarmIgnition.id = alarm.id
                            } else if (alarm.name == this.SEVERE_USE_ALARM_NAME) {
                                this.alarmSevere.model = true;
                                this.alarmSevere.id = alarm.id
                            } else if (alarm.name == this.IDDLE_USE_ALARM_NAME) {
                                this.alarmIddle.model = true;
                                this.alarmIddle.id = alarm.id
                            }
                            this.teste.model = !this.teste.model

                        })
                    }

                })
                .catch(error => {

                })
            //alert('oooo')
        },
        createAlarmMove() {

            this.teste.model = !this.teste.model
            store.dispatch('app-asset/addMoveAlarm', {
                    assetId: this.myId
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')

                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmMove.model = !this.alarmMove.model
                    this.teste.model = !this.teste.model
                    this.fetchAlarms()
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        createAlarmWeekendMove() {
            store.dispatch('app-asset/addAlarmWeekendMove', {
                    assetId: this.myId
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')
                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmWeekendMove.model = !this.alarmWeekendMove.model
                    this.teste.model = !this.teste.model
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        createAlarmIgnition() {
            store.dispatch('app-asset/addIgnitionAlarm', {
                    assetId: this.myId
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')
                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmIgnition.model = !this.alarmIgnition.model
                    this.teste.model = !this.teste.model
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        createAlarmSevere() {
            store.dispatch('app-asset/addSevereAlarm', {
                    assetId: this.myId
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')
                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmSevere.model = !this.alarmSevere.model
                    this.teste.model = !this.teste.model
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        createAlarmIddle() {
            store.dispatch('app-asset/addIddleAlarm', {
                    assetId: this.myId
                })
                .then(response => {
                    this.msgDialog(this.$t('Success in Enable'), 'success')
                    this.fetchAlarms()

                })
                .catch(error => {
                    this.alarmIddle.model = !this.alarmIddle.model
                    this.teste.model = !this.teste.model
                    this.msgDialog(this.$t('An error occurred while trying to enable'), 'error')

                })
        },
        invertModelAlarm(typeAlarm) {
            switch (typeAlarm) {
                case this.MOVE_ALARM_NAME:
                    this.alarmMove.model = !this.alarmMove.model;
                    document.getElementById("check-move").checked = this.alarmMove.model;
                    break;
                case this.WEEKEND_MOVE_ALARM_NAME:
                    this.alarmWeekendMove.model = !this.alarmWeekendMove.model;
                    document.getElementById("check-weekend-move").checked = this.alarmWeekendMove.model;
                    break;
                case this.IGNITION_ALARM_NAME:
                    this.alarmIgnition.model = !this.alarmIgnition.model;
                    document.getElementById("check-ignition").checked = this.alarmIgnition.model;
                    break;
                case this.SEVERE_USE_ALARM_NAME:
                    this.alarmSevere.model = !this.alarmSevere.model;
                    document.getElementById("check-severe-use").checked = this.alarmSevere.model;
                    break;
                case this.IDDLE_USE_ALARM_NAME:
                    this.alarmIddle.model = !this.alarmIddle.model;
                    document.getElementById("check-iddle-use").checked = this.alarmIddle.model;
                    break;
                default:
                    console.log('teste')
            }
            this.teste.model = !this.teste.model

        },
        modalDelete(id, typeAlarm) {
            this.$swal({
                title: this.$t('Are you sure?'),
                //text: this.$t("You can't revert your action"),
                showDenyButton: true,
                //icon: 'warning',
                confirmButtonText: this.$t('Yes'),
                denyButtonText: this.$t('No'),
                showLoaderOnConfirm: true,
                showConfirmButton: true,
                allowOutsideClick: false,
                allowEscapeKey: false,
                customClass: {
                    cancelButton: 'cancelBtn',
                    confirmButton: 'mr-1',
                }
            }).then((result) => {
                if (result.isConfirmed) {
                    this.deleteAlarm(id, typeAlarm)
                } else if (result.isDenied) {

                    this.$nextTick(() => {
                        switch (typeAlarm) {
                            case this.MOVE_ALARM_NAME:
                                this.alarmMove.model = !this.alarmMove.model;
                                document.getElementById("check-move").checked = this.alarmMove.model;
                                break;
                            case this.WEEKEND_MOVE_ALARM_NAME:
                                this.alarmWeekendMove.model = !this.alarmWeekendMove.model;
                                document.getElementById("check-weekend-move").checked = this.alarmWeekendMove.model;
                                break;
                            case this.IGNITION_ALARM_NAME:
                                this.alarmIgnition.model = !this.alarmIgnition.model;
                                document.getElementById("check-ignition").checked = this.alarmIgnition.model;
                                break;
                            case this.SEVERE_USE_ALARM_NAME:
                                this.alarmSevere.model = !this.alarmSevere.model;
                                document.getElementById("check-severe-use").checked = this.alarmSevere.model;
                                break;
                            case this.IDDLE_USE_ALARM_NAME:
                                this.alarmIddle.model = !this.alarmIddle.model;
                                document.getElementById("check-iddle-use").checked = this.alarmIddle.model;
                                break;
                            default:
                                console.log('teste')
                        }
                    })
                }
            })
        },
        deleteAlarm(id, typeAlarm) {
            store.dispatch('app-asset/deleteAlarm', {
                    id: id
                })
                .then(response => {
                    this.msgDialog(this.$t('You disabled the alarm!'), 'warning')
                })
                .catch(error => {

                    
                    this.$swal(this.$t('Error'), this.$t('An error occurred while trying to disable'), 'error')
                })

        },
        modalChangeSpeed() {
            this.$swal({
                title: this.$t('Are you sure?'),
                text: this.$t("You can't revert your action"),
                type: 'warning',
                showCancelButton: true,
                confirmButtonText: this.$t('Yes'),
                cancelButtonText: this.$t('No'),
                showCloseButton: true,
                showLoaderOnConfirm: true,
                customClass: {
                    cancelButton: 'cancelBtn',
                    confirmButton: 'mr-1',
                }
            }).then((result) => {
                if (result.model) {

                } else {}
            })
        },
    },
    setup() {

        //const for names alarms
        const MOVE_ALARM_NAME = "Move Alarm"
        const WEEKEND_MOVE_ALARM_NAME = "Weekend Move Alarm"
        const IGNITION_ALARM_NAME = "Ignition Alarm"
        const SEVERE_USE_ALARM_NAME = "Severe use Alarm"
        const IDDLE_USE_ALARM_NAME = "Iddle use Alarm"

        const ASSET_APP_STORE_MODULE_NAME = 'app-asset'

        // Register module
        if (!store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.registerModule(ASSET_APP_STORE_MODULE_NAME, assetStoreModule)

        // UnRegister on leave
        onUnmounted(() => {
            if (store.hasModule(ASSET_APP_STORE_MODULE_NAME)) store.unregisterModule(ASSET_APP_STORE_MODULE_NAME)
        })

        const {
            // Vertical Menu
            isVerticalMenuCollapsed,

            // Customizer
            isCustomizerOpen,

            // Skin
            skin,
            skinOptions,

            // Content Width
            contentWidth,
            contentWidthOptions,

            // RTL
            isRTL,

            // routerTransition
            routerTransition,
            routerTransitionOptions,

            // Layout Type
            layoutType,
            layoutTypeOptions,

        } = useAppCustomizer()

        // set v-models on alarms
        const alarmMove = {
            id: '',
            model: false
        }
        const alarmWeekendMove = {
            id: '',
            model: false
        }
        const alarmIgnition = {
            id: '',
            model: false
        }
        const alarmSevere = {
            id: '',
            model: false
        }
        const alarmIddle = {
            id: '',
            model: false
        }

        if (layoutType.model === 'horizontal') {
            // Remove semi-dark skin option in horizontal layout
            const skinSemiDarkIndex = skinOptions.findIndex(s => s.model === 'semi-dark')
            delete skinOptions[skinSemiDarkIndex]

            // Remove menu hidden radio in horizontal layout => As we already have switch for it
            const menuHiddneIndex = navbarTypes.findIndex(t => t.model === 'hidden')
            delete navbarTypes[menuHiddneIndex]
        }

        // Perfect Scrollbar
        const perfectScrollbarSettings = {
            maxScrollbarLength: 60,
            wheelPropagation: false,
        }

        return {
            // Vertical Menu
            isVerticalMenuCollapsed,

            // Customizer
            isCustomizerOpen,

            layoutTypeOptions,

            // Perfect Scrollbar
            perfectScrollbarSettings,

            //v-models
            alarmMove,
            alarmWeekendMove,
            alarmIgnition,
            alarmSevere,
            alarmIddle,

            //consts for names alarms
            MOVE_ALARM_NAME,
            WEEKEND_MOVE_ALARM_NAME,
            IGNITION_ALARM_NAME,
            SEVERE_USE_ALARM_NAME,
            IDDLE_USE_ALARM_NAME

        }
    },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style><style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.customizer-section {
    padding: 1.5rem;
    border-bottom: 1px solid #ebe9f1;

    .dark-layout & {
        border-color: $theme-dark-border-color;
    }

    #skin-radio-group ::v-deep {
        .custom-control-inline {
            margin-right: 0.7rem;
        }
    }

    .form-group {
        margin-bottom: 1.5rem;
        ;

        &:last-of-type {
            margin-bottom: 0;
        }

        ::v-deep legend {
            font-weight: 500;
        }
    }
}

.mark-active {
    box-shadow: 0 0 0 0.2rem rgba(38, 143, 255, .5);
}

.ps-customizer-area {
    height: calc(100% - 83px)
}
</style>
