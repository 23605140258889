<template>
  <b-card class="mb-3 ">
    <b-row class="no-gutters fix-height">
      <b-col md="4" class="div-img d-flex align-items-center justify-content-center " :style="{height: 'auto'}">
        <img :src="`${urlImage}`" class="card-img">
        <!-- :src="require(`../assets/${urlImage}`)" -->
      </b-col>
      <b-col xl="8" md="8" sm="8">
        <div class="card-body ">
          <b-card-text> {{ $t('Model') }}: <strong>{{ model }}</strong></b-card-text>
          <b-card-text> {{ $t('Identifier') }}: <strong>{{ chassis }}</strong></b-card-text>
          <b-card-text> {{ $t('Customer') }}: <strong>{{ customerName }}</strong></b-card-text>
          <b-card-text v-show="dealerName"> {{ $t('Dealer') }}: <strong>{{ dealerName }}</strong></b-card-text>
<!--          <b-card-text v-show="lastConnection"> {{ $t('Last Telemetry') }}: <strong>{{ lastConnection }}</strong></b-card-text>-->
          <b-card-text v-show="modemIdentifier"> {{ $t('Modem Identifier') }}: <strong>{{ modemIdentifier }}</strong></b-card-text>
          <b-card-text> Status: &nbsp;&nbsp;
            <b-badge :variant="`${setColor(status)}`" :class="`badge-glow ` ">
              <span
                  :class="`${status == 'noconnection' ? 'noconnection': ''}` ">{{ $t(status ? status : 'noconnection') }}</span>
            </b-badge>
            <b-badge variant="danger" class="badge-glow pulse ml-1" v-show="panic">
              <span> {{ $t('Panic') }}</span>
            </b-badge>
          </b-card-text>
        </div>
      </b-col>

    </b-row>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardText,
  BRow,
  BCol,
  BBadge
} from 'bootstrap-vue'
//import SimpleIndicatorCard from './SimpleIndicatorCard.vue'

export default {
  components: {
    BCard,
    BCardText,
    BRow,
    BCol,
    BBadge
    //SimpleIndicatorCard
  },
  props: {
    model: {
      type: String,
      required: true,
    },
    urlImage: {
      type: String,
      required: true,
    },
    chassis: {
      type: String,
      required: true,
    },
    status: {
      type: String,
      required: true,
    },
    lastConnection: {
      type: String,
      required: true,
    },
    heightImg: {
      type: String,
      default: 'auto',
    },
    customerName: {
      type: String,
      required: true,
    },
    dealerName: {
      type: String,
    },
    modemIdentifier: {
      type: String,
    },
    panic: {
      type: Boolean,
      default: false,
    },
   
  },
  methods: {
    setColor: function (status) {

      if (status === 'failure') return 'danger'
      if (status === 'off') return 'secondary'
      if (status === 'noconnection') return 'light'
      if (status === 'severe') return 'warning'
      if (status === 'iddle') return 'info'
      if (status === 'on') return 'success'
      return 'primary'
    }
  }
}
</script>

<style>
.card-img {
  max-width: 200px !important;
  max-height: 200px !important;
  min-width: 50px !important;
  min-height: 50px !important;
}

.card-text {
  font-size: 0.95em !important;
}

.noconnection {
  color: #726f7f !important;
}

.no-gutters {
  margin: -1.5rem !important;
}

.div-img {
  background-color: #F3F2F7 !important;
}

.fix-height {
  min-height: 198px;
}

.badge-glow.pulse {
  animation: pulse 1s infinite;
}

@keyframes pulse {
  0% {
    box-shadow: 0 0 0 rgba(220, 53, 69, 0.8);
  }
  70% {
    box-shadow: 0 0 10px 10px rgba(220, 53, 69, 0);
  }
  100% {
    box-shadow: 0 0 0 rgba(220, 53, 69, 0);
  }
}
</style>
